<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <form>
          <h5>
            Product details
            <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
          </h5>

          <hr />
          <b-row>
            <b-col lg="6" md="2" sm="2">
              <inline-input
                :id="controls.product_name.id"
                :value="data.product_name"
                :label="controls.product_name.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="2" sm="2">
              <inline-select
                :id="controls.category.id"
                :value="data.category"
                :label="controls.category.label"
                :readonly="controls.category.readonly"
                :options="controls.category.options"
                :allow-empty="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>
            <b-col lg="3" md="2" sm="2">
              <inline-select
                :id="controls.base_product.id"
                :value="data.base_product"
                :label="controls.base_product.label"
                :readonly="controls.base_product.readonly"
                :options="controls.base_product.options"
                :allow-empty="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="2" sm="2">
              <inline-input
                :id="controls.productcode.id"
                :value="data.productcode"
                :label="controls.productcode.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="2" sm="2">
              <inline-input
                :id="controls.serialno.id"
                :value="data.serialno"
                :label="controls.serialno.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="2" sm="2">
              <inline-date-picker
                :id="controls.start_date.id"
                :value-single="data.start_date"
                :label="controls.start_date.label"
                :readonly="controls.start_date.readonly"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
            <b-col lg="3" md="6">
              <inline-select
                :id="controls.discontinued.id"
                :value="data.discontinued"
                :label="controls.discontinued.label"
                :readonly="controls.discontinued.readonly"
                :options="controls.discontinued.options"
                :allow-empty="false"
                :mode="mode"
                @changed="updateSelect"
                :required="false"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="2" sm="2">
              <inline-input
                :id="controls.qty_per_unit.id"
                :value="data.qty_per_unit"
                :label="controls.qty_per_unit.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="2" sm="2">
              <inline-input
                :id="controls.unit_price.id"
                :value="data.unit_price"
                :label="controls.unit_price.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>

            <b-col lg="3" md="2" sm="2">
              <inline-date-picker
                :id="controls.sales_start_date.id"
                :value-single="data.sales_start_date"
                :label="controls.sales_start_date.label"
                :readonly="controls.sales_start_date.readonly"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
            <b-col lg="3" md="2" sm="2">
              <inline-date-picker
                :id="controls.sales_end_date.id"
                :value-single="data.sales_end_date"
                :label="controls.sales_end_date.label"
                :readonly="controls.sales_end_date.readonly"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="2" sm="2">
              <inline-input
                :id="controls.weight.id"
                :value="data.weight"
                :label="controls.weight.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="2" sm="2">
              <inline-input
                :id="controls.pack_size.id"
                :value="data.pack_size"
                :label="controls.pack_size.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>

            <b-col lg="6" md="2" sm="2">
              <inline-input
                :id="controls.description.id"
                :value="data.description"
                :label="controls.description.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>

          <hr />
        </form>

        <hr />
        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey($route.meta.module, id),
            next: $customTable.getNextKey($route.meta.module, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey($route.meta.module, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey($route.meta.module, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Products' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Product submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Product submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";
import InlineDatePicker from "@/components/InlineDatePicker";

import { mapState, mapActions } from "vuex";

export default {
  name: "ProductSubmissionForm",
  props: {
    id: {
      type: String,
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    InlineDatePicker,
    FormSubmissionActions
  },
  data: function() {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      controls: {
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        product_name: {
          id: "input:product_name",
          label: "Product Name",
          changed: false,
          readonly: false
        },
        category: {
          id: "select:category",
          label: "Category",
          options: [],
          changed: false,
          readonly: false
        },
        base_product: {
          id: "select:base_product",
          label: "Base Product",
          options: [],
          changed: false,
          readonly: false
        },
        qty_per_unit: {
          id: "input:qty_per_unit",
          label: "Qty per Unit",
          changed: false,
          readonly: false
        },
        unit_price: {
          id: "input:unit_price",
          label: "Unit Price",
          changed: false,
          readonly: false
        },
        productcode: {
          id: "input:productcode",
          label: "Product Code",
          changed: false,
          readonly: false
        },
        weight: {
          id: "input:weight",
          label: "Weight",
          changed: false,
          readonly: false
        },
        pack_size: {
          id: "input:pack_size",
          label: "Pack Size",
          changed: false,
          readonly: false
        },
        sales_start_date: {
          id: "datepicker:sales_start_date",
          label: "Sales Start Date",
          changed: false,
          readonly: false
        },
        sales_end_date: {
          id: "datepicker:sales_end_date",
          label: "Sales End Date",
          changed: false,
          readonly: false
        },
        start_date: {
          id: "datepicker:start_date",
          label: "Start Date",
          changed: false,
          readonly: false
        },
        discontinued: {
          id: "select:discontinued",
          label: "Discontinued",
          options: this.$form.yesNo.options,
          changed: false,
          readonly: false
        },
        serialno: {
          id: "input:serialno",
          label: "Serialno",
          changed: false,
          readonly: false
        },
        description: {
          id: "input:description",
          label: "Description",
          changed: false,
          readonly: false
        }
      },
      data: {
        id: "",
        product_name: "",
        category: {
          id: "",
          label: ""
        },
        base_product: {
          id: "",
          label: ""
        },
        qty_per_unit: "",
        unit_price: "",
        productcode: "",
        weight: "",
        pack_size: "",
        sales_start_date: "",
        sales_end_date: "",
        start_date: "",
        discontinued: this.$form.yesNo.no,
        serialno: "",
        description: ""
      },
      datasetKeys: []
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    initialize: async function() {
      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.data.id = this.id;

      this.fetchDropdowns();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        await this.fetchData(this.id);
      }

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.data.start_date = moment.utc().format("YYYY-MM-DD");
      }
    },

    fetchDropdowns() {
      this.$api.get("products/categories").then(response => {
        this.controls.category.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });
      this.$api.get("products/finished-products").then(response => {
        this.controls.base_product.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });
    },
    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let method = "";

      let url = "products";

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
        url = "products";
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = "put";
        url = `products/${this.data.id}`;
      }

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateField(field, value, mode) {
      this.$form.updateField("products", this, field, value, mode);
    },
    updateSelect(id, value) {
      this.$form.updateField("products", this, id, value, this.mode);
    },

    fetchData: async function(id) {
      let self = this;

      this.isLoading = true;
      return this.$api
        .get(`products/${id}`)
        .then(response => {
          this.isLoading = false;

          if (self.$_.isEmpty(response)) return;

          self.data.id = self.id;

          self.data.product_name = response["Product Name"];
          self.data.category = {
            id: response["Category"],
            label: response["Category"]
          };

          self.data.base_product = {
            id: response["base_product_id"],
            label: response["Base Product"]
          };

          self.data.qty_per_unit = response["Qty/Unit"];
          self.data.unit_price = response["Unit Price"];

          self.data.weight = response["Weight"];
          self.data.pack_size = response["Pack Size"];
          self.data.sales_start_date = response["Sales Start Date"];
          self.data.sales_end_date = response["Sales End Date"];
          self.data.start_date = response["Start Date"];
          //  self.data.discontinued = { id: response['discontinued_id'], label: this.$form.yesNo.find( i => i === response['discontinued_id'])}
          self.data.productcode = response["Product Code"];
          self.data.serialno = response["Serial No"];
          self.data.description = response["Description"];
        })
        .catch(error => {
          this.isLoading = false;

          console.log(error);
        });
    }
  },
  watch: {}
};
</script>

<style></style>
